import React, { useState } from "react";
import { Box, Button, Input, Snackbar, Alert } from "@mui/material";

interface UploadCsvProps {
	onUpload: (file: File) => void;
}

const UploadCsv: React.FC<UploadCsvProps> = ({ onUpload }) => {
	const [file, setFile] = useState<File | null>(null);
	const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: "success" | "error" }>({
		open: false,
		message: "",
		severity: "error"
	});

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			setFile(event.target.files[0]);
		}
	};

	const handleUpload = () => {
		if (file) {
			onUpload(file);
		} else {
			setSnackbar({ open: true, message: "Please select a file to upload", severity: "error" });
		}
	};

	const handleCloseSnackbar = () => {
		setSnackbar({ ...snackbar, open: false });
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="center" mt={2}>
			<Input type="file" onChange={handleFileChange} />
			<Button onClick={handleUpload} variant="contained" color="primary" sx={{ mt: 2 }}>
				Generate
			</Button>

			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
				<Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default UploadCsv;
