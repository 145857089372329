import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Autocomplete,
	TextField,
	Box,
	Button,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Modal,
	Fab,
	useMediaQuery,
	Card,
	CardContent,
	Snackbar,
	Alert,
	Select,
	MenuItem,
	FormControl,
	InputLabel
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import api from "../utils/api";
import NavBar from "../components/NavBar";

interface Ingredient {
	Id: string;
	name: string;
	quantity: string;
	measurementUnit: string;
}

interface Meal {
	Id: string;
	name: string;
	ingredients: MealIngredient[];
}

interface MealIngredient {
	mealId: string;
	ingredientId: string;
	ingredientName: string;
	Id: string;
	quantity: string;
	measurementUnit: string;
	multiplier: number;
}

const MealDetailPage: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [meal, setMeal] = useState<Meal | null>(null);
	const [ingredients, setIngredients] = useState<Ingredient[]>([]);
	const [newIngredient, setNewIngredient] = useState<Ingredient | null>(null);
	const [cookedQuantity, setCookedQuantity] = useState("");
	const [measurementUnit, setUnit] = useState("grams");
	const [multiplier, setMultiplier] = useState(1);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [editingIngredientId, setEditingIngredientId] = useState<string | null>(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [ingredientToDelete, setIngredientToDelete] = useState<MealIngredient | null>(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: "success" | "error" }>({
		open: false,
		message: "",
		severity: "success"
	});

	useEffect(() => {
		const fetchMeal = async () => {
			try {
				const response = await api.get<Meal>(`/meals/${id}`);
				setMeal(response.data);
			} catch (error) {
				console.error("Failed to fetch meal details", error);
			}
		};

		const fetchIngredients = async () => {
			try {
				const response = await api.get<Ingredient[]>("/ingredients");
				setIngredients(response.data);
			} catch (error) {
				console.error("Failed to fetch ingredients", error);
			}
		};

		fetchMeal();
		fetchIngredients();
	}, [id]);

	const handleAddIngredient = async () => {
		if (!newIngredient || !cookedQuantity.trim()) {
			setSnackbar({ open: true, message: "Please select an ingredient and enter the cooked weight", severity: "error" });
			return;
		}

		try {
			// Use the cookedQuantity as provided and save it directly
			const mealIngredientData = {
				mealId: id,
				ingredientId: newIngredient.Id,
				ingredientName: newIngredient.name,
				quantity: cookedQuantity, // Save the cooked weight directly
				measurementUnit,
				multiplier
			};

			if (isEditMode && editingIngredientId) {
				await api.put(`/meals/${id}/ingredients/${editingIngredientId}`, mealIngredientData);
				setSnackbar({ open: true, message: "Ingredient updated successfully", severity: "success" });
			} else {
				await api.post(`/meals/${id}/ingredients`, mealIngredientData);
				setSnackbar({ open: true, message: "Ingredient added successfully", severity: "success" });
			}

			const response = await api.get<Meal>(`/meals/${id}`);
			setMeal(response.data);
			resetForm();
		} catch (error) {
			console.error("Failed to add/update ingredient", error);
			setSnackbar({ open: true, message: "Failed to add/update ingredient", severity: "error" });
		}
	};

	const handleEditIngredient = (ingredient: MealIngredient) => {
		setIsEditMode(true);
		setEditingIngredientId(ingredient.ingredientId);
		setNewIngredient({
			Id: ingredient.ingredientId,
			name: ingredient.ingredientName,
			quantity: ingredient.quantity,
			measurementUnit: ingredient.measurementUnit
		});
		setCookedQuantity(ingredient.quantity);
		setUnit(ingredient.measurementUnit);
		setMultiplier(ingredient.multiplier);
		setIsModalOpen(true);
	};

	const handleRemoveIngredient = (ingredient: MealIngredient) => {
		setIngredientToDelete(ingredient);
		setIsDeleteModalOpen(true);
	};

	const confirmDeleteIngredient = async () => {
		if (!meal || !ingredientToDelete) return;

		try {
			await api.delete(`/meals/${meal.Id}/ingredients/${ingredientToDelete.ingredientId}`);
			const response = await api.get<Meal>(`/meals/${id}`);
			setMeal(response.data);
			setSnackbar({ open: true, message: "Ingredient removed successfully", severity: "success" });
		} catch (error) {
			console.error("Failed to remove ingredient", error);
			setSnackbar({ open: true, message: "Failed to remove ingredient", severity: "error" });
		} finally {
			setIsDeleteModalOpen(false);
			setIngredientToDelete(null);
		}
	};

	const handleCloseSnackbar = () => {
		setSnackbar({ ...snackbar, open: false });
	};

	const resetForm = () => {
		setNewIngredient(null);
		setCookedQuantity("");
		setUnit("grams");
		setMultiplier(1);
		setIsModalOpen(false);
		setIsEditMode(false);
		setEditingIngredientId(null);
	};

	if (!meal) {
		return <Typography>Loading...</Typography>;
	}

	return (
		<>
			<NavBar />
			<Box p={3}>
				<Typography variant="h4" gutterBottom>
					{meal.name}
				</Typography>
				<Typography variant="h5" gutterBottom>
					Ingredients
				</Typography>

				{isMobile ? (
					// Convert to cards on mobile
					<Box display="flex" flexDirection="column" gap={2}>
						{meal.ingredients.map((ingredient) => (
							<Card key={ingredient.Id} sx={{ p: 2, borderRadius: 2, boxShadow: 2 }}>
								<CardContent>
									<Box display="flex" justifyContent="space-between" alignItems="center">
										<Box>
											<Typography variant="body1" fontWeight="bold">
												Name:
											</Typography>
											<Typography variant="subtitle1" gutterBottom>
												{ingredient.ingredientName}
											</Typography>
										</Box>
										<Box display="flex">
											<IconButton
												aria-label="edit"
												onClick={() => handleEditIngredient(ingredient)}
												sx={{ color: "black", mr: 1 }}>
												<EditIcon />
											</IconButton>
											<IconButton
												aria-label="delete"
												onClick={() => handleRemoveIngredient(ingredient)}
												sx={{ color: "black" }}>
												<DeleteIcon />
											</IconButton>
										</Box>
									</Box>
									<Box display="flex" justifyContent="flex-start" gap={4} mt={2}>
										<Box>
											<Typography variant="body2" fontWeight="bold">
												Cooked Weight / Quantity:
											</Typography>
											<Typography variant="body2">
												{ingredient.quantity} {ingredient.measurementUnit}
											</Typography>
										</Box>
										<Box>
											<Typography variant="body2" fontWeight="bold">
												Raw Weight:
											</Typography>
											<Typography variant="body2">
												{Math.round(parseFloat(ingredient.quantity) / ingredient.multiplier)} {ingredient.measurementUnit}
											</Typography>
										</Box>
									</Box>
								</CardContent>
							</Card>
						))}
					</Box>
				) : (
					// Display as table on larger screens
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<b>Name</b>
									</TableCell>
									<TableCell>
										<b>Cooked Weight / Quantity</b>
									</TableCell>
									<TableCell>
										<b>Raw Weight / Quantity</b>
									</TableCell>
									<TableCell>
										<b>Actions</b>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{meal.ingredients.length > 0 ? (
									meal.ingredients.map((ingredient) => (
										<TableRow key={ingredient.Id}>
											<TableCell>{ingredient.ingredientName}</TableCell>
											<TableCell>
												{ingredient.quantity} {ingredient.measurementUnit}
											</TableCell>
											<TableCell>
												{Math.round(parseFloat(ingredient.quantity) / ingredient.multiplier)} {ingredient.measurementUnit}
											</TableCell>
											<TableCell>
												<IconButton edge="end" aria-label="edit" onClick={() => handleEditIngredient(ingredient)}>
													<EditIcon />
												</IconButton>
												<IconButton edge="end" aria-label="delete" onClick={() => handleRemoveIngredient(ingredient)}>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell colSpan={4}>
											<Typography>No ingredients found.</Typography>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				)}

				{/* Confirmation modal for deleting ingredient */}
				<Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
					<Box p={3} bgcolor="background.paper" borderRadius={2} mx="auto" my="10vh" width="80vw" maxWidth={400} textAlign="center">
						<Typography variant="h5" gutterBottom>
							Confirm Delete
						</Typography>
						<Typography variant="body1" gutterBottom>
							Are you sure you want to delete this ingredient from the meal?
						</Typography>
						<Box display="flex" justifyContent="space-between" mt={3}>
							<Button variant="outlined" onClick={() => setIsDeleteModalOpen(false)}>
								Cancel
							</Button>
							<Button variant="contained" color="error" onClick={confirmDeleteIngredient}>
								Delete
							</Button>
						</Box>
					</Box>
				</Modal>

				<Fab color="primary" aria-label="add" onClick={() => setIsModalOpen(true)} sx={{ position: "fixed", bottom: 16, right: 16 }}>
					<AddIcon />
				</Fab>

				<Modal open={isModalOpen} onClose={resetForm}>
					<Box p={3} bgcolor="background.paper" borderRadius={2} mx="auto" my="10vh" width="80vw" maxWidth={400} textAlign="center">
						<Typography variant="h5" gutterBottom>
							{isEditMode ? "Edit Ingredient" : "Add New Ingredient"}
						</Typography>
						<Box display="flex" flexDirection="column" gap={2}>
							<Autocomplete
								options={ingredients}
								getOptionLabel={(option) => option.name}
								value={newIngredient}
								onChange={(event, newValue) => setNewIngredient(newValue)}
								renderInput={(params) => <TextField {...params} label="Select Ingredient" variant="outlined" />}
							/>
							<TextField
								label="Cooked Weight / Quantity"
								type="number"
								value={cookedQuantity}
								onChange={(e) => setCookedQuantity(e.target.value)}
								variant="outlined"
								inputProps={{ step: 10 }}
							/>
							<FormControl variant="outlined" fullWidth>
								<InputLabel>Unit</InputLabel>
								<Select sx={{ textAlign: "left" }} value={measurementUnit} onChange={(e) => setUnit(e.target.value)} label="Unit">
									<MenuItem value="grams">grams</MenuItem>
									<MenuItem value="ml">ml</MenuItem>
									<MenuItem value="qty">qty</MenuItem>
								</Select>
							</FormControl>
							{measurementUnit !== "qty" && (
								<>
									<TextField
										label="Multiplier"
										type="number"
										value={multiplier}
										onChange={(e) => setMultiplier(Number(e.target.value))}
										variant="outlined"
										inputProps={{ step: 0.1 }}
									/>
									<Box mt={1} p={2} bgcolor="#f0f0f0" borderRadius={1}>
										<Typography variant="body1" fontWeight="bold" color="textSecondary">
											Raw Weight / Quantity{" "}
											<Typography component="span" variant="body1" fontWeight="bold" color="primary">
												{cookedQuantity && multiplier ? Math.round(parseFloat(cookedQuantity) / multiplier) : ""}{" "}
												{cookedQuantity && multiplier ? measurementUnit : ""}
											</Typography>
										</Typography>
									</Box>
								</>
							)}
							<Button variant="contained" onClick={handleAddIngredient}>
								{isEditMode ? "Update" : "Save"}
							</Button>
						</Box>
					</Box>
				</Modal>

				<Snackbar
					open={snackbar.open}
					autoHideDuration={6000}
					onClose={handleCloseSnackbar}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
					<Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
						{snackbar.message}
					</Alert>
				</Snackbar>
			</Box>
		</>
	);
};

export default MealDetailPage;
