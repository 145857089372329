import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "react-oidc-context";

const cognitoAuthConfig = {
	authority: process.env.REACT_APP_AUTHORITY || "",
	client_id: process.env.REACT_APP_CLIENT_ID || "",
	redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI || "http://localhost:3000/home",
	response_type: "code",
	scope: "openid email", // Include openid for OIDC compliance
	post_logout_redirect_uri: process.env.REACT_APP_LOGOUT_REDIRECT_URI || "http://localhost:3000"
};

if (!cognitoAuthConfig.authority || !cognitoAuthConfig.client_id || !cognitoAuthConfig.redirect_uri) {
	console.error("Missing required configuration for Cognito.");
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<AuthProvider {...cognitoAuthConfig}>
			<App />
		</AuthProvider>
	</React.StrictMode>
);

reportWebVitals(console.log);
