import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
	palette: {
		primary: {
			main: "#383838"
		},
		background: {
			default: "#ffffff"
		}
	},
	typography: {
		fontFamily: "Roboto, Helvetica, Arial, sans-serif"
	}
});

export default lightTheme;
