import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./themes";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import MealListPage from "./pages/MealListPage";
import AddMeal from "./components/AddMeal";
import IngredientsList from "./pages/IngredientsList";
import MealDetailPage from "./pages/MealDetailPage";
import IngredientDetailPage from "./pages/IngredientDetailPage";
import PrivateRoute from "./components/PrivateRoute";

const App: React.FC = () => {
	const [useDarkTheme] = useState(false);
	const currentTheme = useDarkTheme ? darkTheme : lightTheme;

	return (
		<ThemeProvider theme={currentTheme}>
			<Router>
				<Routes>
					{/* Redirect root to /home */}
					<Route path="/" element={<Navigate to="/home" />} />

					{/* Private Routes - Protected Content */}
					<Route
						path="/home"
						element={
							<PrivateRoute>
								<HomePage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/meals"
						element={
							<PrivateRoute>
								<MealListPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/meals/add"
						element={
							<PrivateRoute>
								<AddMeal />
							</PrivateRoute>
						}
					/>
					<Route
						path="/ingredients/"
						element={
							<PrivateRoute>
								<IngredientsList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/ingredients/:id"
						element={
							<PrivateRoute>
								<IngredientDetailPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/meals/:id"
						element={
							<PrivateRoute>
								<MealDetailPage />
							</PrivateRoute>
						}
					/>
				</Routes>
			</Router>
		</ThemeProvider>
	);
};

export default App;
