import React, { useState, useEffect } from "react";
import {
	Typography,
	Box,
	TextField,
	Snackbar,
	Alert,
	Modal,
	Fab,
	Button,
	InputAdornment,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import api from "../utils/api";
import Navbar from "../components/NavBar";

interface Meal {
	Id: string;
	name: string;
}

const MealListPage: React.FC = () => {
	const [meals, setMeals] = useState<Meal[]>([]);
	const [filteredMeals, setFilteredMeals] = useState<Meal[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mealName, setMealName] = useState("");
	const [searchQuery, setSearchQuery] = useState("");
	const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: "success" | "error" }>({
		open: false,
		message: "",
		severity: "success"
	});
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedMeal, setSelectedMeal] = useState<Meal | null>(null);
	const navigate = useNavigate();

	// Fetch meals on mount
	useEffect(() => {
		const fetchMeals = async () => {
			try {
				const response = await api.get<Meal[]>("/meals");
				setMeals(response.data);
				setFilteredMeals(response.data);
			} catch (error) {
				console.error("Failed to fetch meals", error);
			}
		};
		fetchMeals();
	}, []);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setSearchQuery(value);
		if (value) {
			const filtered = meals.filter((meal) => meal.name.toLowerCase().includes(value.toLowerCase()));
			setFilteredMeals(filtered);
		} else {
			setFilteredMeals(meals);
		}
	};

	const handleMealClick = (mealId: string) => {
		navigate(`/meals/${mealId}`);
	};

	const handleAddMealClick = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
		setMealName("");
	};

	const handleAddMeal = async () => {
		if (!mealName.trim()) {
			setSnackbar({ open: true, message: "Please enter a meal name", severity: "error" });
			return;
		}

		try {
			const newMeal = { Id: Date.now().toString(), name: mealName };
			await api.post("/meals", newMeal);
			setMealName("");
			setIsModalOpen(false);

			const response = await api.get<Meal[]>("/meals");
			setMeals(response.data);
			setFilteredMeals(response.data);
			setSnackbar({ open: true, message: "Meal added successfully", severity: "success" });
		} catch (error) {
			console.error("Failed to add meal", error);
			setSnackbar({ open: true, message: "Failed to add meal", severity: "error" });
		}
	};

	const handleDeleteMeal = (meal: Meal) => {
		setSelectedMeal(meal);
		setIsDeleteModalOpen(true);
	};

	const confirmDeleteMeal = async () => {
		if (!selectedMeal) return;

		try {
			await api.delete(`/meals/${selectedMeal.Id}`);
			const updatedMeals = meals.filter((meal) => meal.Id !== selectedMeal.Id);
			setMeals(updatedMeals);
			setFilteredMeals(updatedMeals);
			setSnackbar({ open: true, message: "Meal deleted successfully", severity: "success" });
		} catch (error) {
			console.error("Failed to delete meal", error);
			setSnackbar({ open: true, message: "Failed to delete meal", severity: "error" });
		} finally {
			setIsDeleteModalOpen(false);
			setSelectedMeal(null);
		}
	};

	const handleCloseSnackbar = () => {
		setSnackbar({ ...snackbar, open: false });
	};

	return (
		<>
			<Navbar />
			<Box sx={{ p: 3 }}>
				{/* Search Box with Sticky Position */}
				<Box sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1, pb: 2 }}>
					<Typography variant="h4" gutterBottom>
						Meal List
					</Typography>

					<TextField
						label="Search Meals"
						value={searchQuery}
						onChange={handleSearchChange}
						InputProps={{
							endAdornment: searchQuery && (
								<InputAdornment position="end">
									<IconButton onClick={() => setSearchQuery("")}>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
						fullWidth
					/>
				</Box>

				{/* Meal List */}
				<List>
					{filteredMeals.length > 0 ? (
						filteredMeals
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((meal) => (
								<ListItem key={meal.Id}>
									<ListItemText sx={{ cursor: "pointer" }} primary={meal.name} onClick={() => handleMealClick(meal.Id)} />
									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete" onClick={() => handleDeleteMeal(meal)}>
											<DeleteIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
					) : (
						<Typography>No meals found.</Typography>
					)}
				</List>
			</Box>

			{/* Add Meal Floating Action Button */}
			<Fab color="primary" aria-label="add" onClick={handleAddMealClick} sx={{ position: "fixed", bottom: 16, right: 16 }}>
				<AddIcon />
			</Fab>

			{/* Add Meal Modal */}
			<Modal open={isModalOpen} onClose={handleModalClose} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<Box sx={{ width: "80vw", maxWidth: 400, bgcolor: "background.paper", p: 3, borderRadius: 2, boxShadow: 24, textAlign: "center" }}>
					<Typography variant="h5" gutterBottom>
						Add New Meal
					</Typography>
					<TextField
						label="Meal Name"
						value={mealName}
						onChange={(e) => setMealName(e.target.value)}
						variant="outlined"
						fullWidth
						sx={{ mb: 3 }}
					/>
					<Button variant="contained" onClick={handleAddMeal} fullWidth>
						Save
					</Button>
				</Box>
			</Modal>

			{/* Delete Confirmation Modal */}
			<Modal
				open={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<Box sx={{ width: "80vw", maxWidth: 400, bgcolor: "background.paper", p: 4, borderRadius: 2, boxShadow: 24 }}>
					<Typography variant="h6" gutterBottom>
						Delete Meal
					</Typography>
					<Typography>Are you sure you want to delete this meal?</Typography>
					<Box mt={3} display="flex" justifyContent="space-between">
						<Button variant="outlined" onClick={() => setIsDeleteModalOpen(false)}>
							Cancel
						</Button>
						<Button variant="contained" color="error" onClick={confirmDeleteMeal}>
							Delete
						</Button>
					</Box>
				</Box>
			</Modal>

			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
				<Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default MealListPage;
