import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Box, Typography, List, ListItemButton, ListItemText, Stack, CircularProgress, Snackbar, Alert } from "@mui/material";
import api from "../utils/api";
import NavBar from "../components/NavBar";

interface MealIngredient {
	ingredientId: string;
	ingredientName: string;
	quantity: number;
	measurementUnit: string;
}

interface Meal {
	Id: string;
	name: string;
	ingredients: MealIngredient[];
}

interface Ingredient {
	Id: string;
	name: string;
}

const IngredientDetailPage: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [ingredient, setIngredient] = useState<Ingredient | null>(null);
	const [meals, setMeals] = useState<Meal[]>([]);
	const [loading, setLoading] = useState(true);
	const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: "success" | "error" }>({
		open: false,
		message: "",
		severity: "success"
	});

	useEffect(() => {
		const fetchIngredientDetails = async () => {
			try {
				setLoading(true);
				// Fetch ingredient details
				const ingredientResponse = await api.get<Ingredient>(`/ingredients/${id}`);
				if (ingredientResponse.data) {
					setIngredient(ingredientResponse.data);
				}

				// Fetch meals containing the ingredient
				const mealsResponse = await api.get<Meal[]>(`/meals/ingredient/${id}`);
				if (mealsResponse.data) {
					const mealsWithIngredients = await Promise.all(
						mealsResponse.data.map(async (meal) => {
							const ingredientsResponse = await api.get(`/meals/${meal.Id}/ingredients`);

							return {
								...meal,
								ingredients: ingredientsResponse.data || []
							};
						})
					);

					// Sort the data by meal name (ascending order)
					mealsWithIngredients.sort((a, b) => a.name.localeCompare(b.name));

					setMeals(mealsWithIngredients);
				}
			} catch (error) {
				console.error("Failed to fetch ingredient details or meals", error);
				setSnackbar({ open: true, message: "Failed to load ingredient details", severity: "error" });
			} finally {
				setLoading(false);
			}
		};

		fetchIngredientDetails();
	}, [id]);

	const handleCloseSnackbar = () => {
		setSnackbar({ ...snackbar, open: false });
	};

	if (loading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height="100vh">
				<CircularProgress />
			</Box>
		);
	}

	if (!ingredient) {
		return (
			<>
				<NavBar />
				<Box p={3}>
					<Typography variant="h5">Ingredient not found</Typography>
				</Box>
			</>
		);
	}

	return (
		<>
			<NavBar />
			<Box p={3}>
				<Typography variant="h4" gutterBottom>
					{ingredient.name}
				</Typography>
				<Typography variant="h5" gutterBottom>
					Meals Containing This Ingredient
				</Typography>

				{meals.length > 0 ? (
					<List>
						<Stack direction="row" spacing={2} pb={2}>
							<Typography variant="subtitle1" fontWeight="bold" sx={{ flex: 1 }}>
								Meal Name
							</Typography>
							<Typography variant="subtitle1" fontWeight="bold" sx={{ flex: 1 }}>
								Cooked Weight
							</Typography>
						</Stack>
						{meals.map((meal) => {
							const mealIngredient =
								meal.ingredients && meal.ingredients.length > 0
									? meal.ingredients.find((ing) => ing.ingredientId === ingredient?.Id)
									: null;

							const cookedWeight =
								mealIngredient && mealIngredient.quantity ? `${mealIngredient.quantity} ${mealIngredient.measurementUnit}` : "N/A";

							return (
								<ListItemButton
									key={meal.Id}
									component={RouterLink}
									to={`/meals/${meal.Id}`}
									sx={{
										"&:hover": {
											backgroundColor: "#f5f5f5"
										}
									}}>
									<Stack direction="row" spacing={2} width="100%">
										<ListItemText primary={meal.name} sx={{ flex: 1 }} />
										<ListItemText primary={cookedWeight} sx={{ flex: 1 }} />
									</Stack>
								</ListItemButton>
							);
						})}
					</List>
				) : (
					<Typography variant="body1">No meals use this ingredient.</Typography>
				)}
			</Box>

			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
				<Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default IngredientDetailPage;
