import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
	palette: {
		primary: {
			main: "#ffffff"
		},
		background: {
			default: "#121212"
		}
	},
	typography: {
		fontFamily: "Roboto, Helvetica, Arial, sans-serif"
	}
});

export default darkTheme;
