import React, { useState, useLayoutEffect, useRef } from "react";
import {
	Box,
	TextField,
	Typography,
	Snackbar,
	Alert,
	Modal,
	Fab,
	InputAdornment,
	IconButton,
	Button,
	List,
	ListItemButton,
	ListItemText
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link as RouterLink } from "react-router-dom";
import api from "../utils/api";
import Navbar from "../components/NavBar";

const IngredientsList: React.FC = () => {
	const [ingredientName, setIngredientName] = useState("");
	const [ingredients, setIngredients] = useState<{ Id: string; name: string }[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: "success" | "error" }>({
		open: false,
		message: "",
		severity: "success"
	});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedIngredient, setSelectedIngredient] = useState<{ Id: string; name: string } | null>(null);
	const [deleteModalMessage, setDeleteModalMessage] = useState("");
	const [meals, setMeals] = useState<{ name: string }[]>([]);
	const [navbarHeight, setNavbarHeight] = useState(0);

	const navbarRef = useRef<HTMLDivElement | null>(null);

	// Fetch all ingredients on component mount
	useLayoutEffect(() => {
		const fetchIngredients = async () => {
			try {
				const response = await api.get("/ingredients");
				const sortedIngredients = response.data.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));
				setIngredients(sortedIngredients);
			} catch (error) {
				console.error("Failed to fetch ingredients", error);
			}
		};
		fetchIngredients();
	}, []);

	// Calculate the height of the navbar and set it
	useLayoutEffect(() => {
		if (navbarRef.current) {
			setNavbarHeight(navbarRef.current.offsetHeight);
		}
	}, []);

	// Handle form submission for adding a new ingredient
	const handleSubmit = async () => {
		const formattedIngredientName = ingredientName.trim();
		if (!formattedIngredientName) {
			setSnackbar({
				open: true,
				message: "Please enter an ingredient name",
				severity: "error"
			});
			return;
		}

		// Check if ingredient already exists
		if (ingredients.some((ingredient) => ingredient.name === formattedIngredientName)) {
			setSnackbar({
				open: true,
				message: "Ingredient already exists",
				severity: "error"
			});
			return;
		}

		const newIngredient = { name: formattedIngredientName };

		try {
			await api.post("/ingredients", newIngredient);
			setSnackbar({ open: true, message: "Ingredient added successfully", severity: "success" });
			setIngredientName("");

			// Refetch ingredients after adding new one
			const response = await api.get("/ingredients");
			const sortedIngredients = response.data.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));
			setIngredients(sortedIngredients);
		} catch (error) {
			console.error("Failed to add ingredient", error);
			setSnackbar({ open: true, message: "Failed to add ingredient", severity: "error" });
		}
		closeModal();
	};

	// Handle ingredient delete action
	const handleDeleteIngredient = async (ingredient: { Id: string; name: string }) => {
		try {
			let fetchedMeals = [];

			// Check if the ingredient is part of any meal
			try {
				const response = await api.get(`/meals/ingredient/${ingredient.Id}`);
				fetchedMeals = response.data;
			} catch (error) {
				if (error instanceof Error && "response" in error) {
					const axiosError = error as any;
					if (axiosError.response && axiosError.response.status === 404) {
						console.log("No meals found with the given ingredient.");
					} else {
						throw error;
					}
				} else {
					throw error;
				}
			}

			// Set the selected ingredient and show the confirmation modal with a message
			setSelectedIngredient(ingredient);
			setMeals(fetchedMeals);

			if (fetchedMeals.length > 0) {
				setDeleteModalMessage(
					"This ingredient is part of the following meals. Deleting it will remove it from all associated meals. Do you want to proceed?"
				);
			} else {
				setDeleteModalMessage("Are you sure you want to delete this ingredient?");
			}
			setIsDeleteModalOpen(true);
		} catch (error) {
			console.error("Failed to check ingredient associations", error);
			setSnackbar({ open: true, message: "Failed to check ingredient associations", severity: "error" });
		}
	};

	// Confirm deletion of ingredient and remove from meals
	const confirmDeleteIngredient = async () => {
		if (!selectedIngredient) return;

		try {
			// Delete the ingredient from meals
			await api.delete(`/mealIngredients/ingredients/${selectedIngredient.Id}`);
			// Delete the ingredient
			await api.delete(`/ingredients/${selectedIngredient.Id}`);

			setSnackbar({
				open: true,
				message: "Ingredient and related meal ingredients if required were deleted successfully",
				severity: "success"
			});

			// Update ingredient list
			const updatedIngredients = ingredients.filter((i) => i.Id !== selectedIngredient.Id);
			setIngredients(updatedIngredients);
		} catch (error) {
			console.error("Failed to delete ingredient and related meal ingredients", error);
			setSnackbar({ open: true, message: "Failed to delete ingredient", severity: "error" });
		} finally {
			setIsDeleteModalOpen(false);
			setSelectedIngredient(null);
		}
	};

	const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => {
		setIsModalOpen(false);
		setIngredientName(""); // Clear input field when closing
	};

	const filteredIngredients = ingredients.filter((ingredient) => ingredient.name.toLowerCase().includes(searchQuery.toLowerCase()));

	return (
		<>
			<Navbar ref={navbarRef} />
			<Box sx={{ p: 3 }}>
				{/* Search Box with Sticky Position */}
				<Box sx={{ position: "sticky", top: `${navbarHeight}px`, backgroundColor: "white", zIndex: 1, paddingBottom: 2 }}>
					<Typography variant="h4" gutterBottom>
						Manage Ingredients
					</Typography>

					<TextField
						label="Search Ingredients"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						InputProps={{
							endAdornment: searchQuery && (
								<InputAdornment position="end">
									<IconButton onClick={() => setSearchQuery("")}>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
						fullWidth
					/>
				</Box>

				{/* Ingredient List */}
				<List>
					{filteredIngredients.length > 0 ? (
						filteredIngredients.map((ingredient) => (
							<ListItemButton
								key={ingredient.Id}
								component={RouterLink}
								to={`/ingredients/${ingredient.Id}`} // Navigate to IngredientDetailPage for the selected ingredient
							>
								<ListItemText primary={ingredient.name} />
								<IconButton
									edge="end"
									aria-label="delete"
									onClick={(e) => {
										e.stopPropagation(); // Prevent navigating when clicking the delete button
										handleDeleteIngredient(ingredient);
									}}>
									<DeleteIcon />
								</IconButton>
							</ListItemButton>
						))
					) : (
						<Typography>No ingredients found.</Typography>
					)}
				</List>
			</Box>

			{/* Add Ingredient Floating Action Button */}
			<Fab color="primary" aria-label="add" onClick={openModal} sx={{ position: "fixed", bottom: 16, right: 16 }}>
				<AddIcon />
			</Fab>

			{/* Add Ingredient Modal */}
			<Modal
				open={isModalOpen}
				onClose={closeModal}
				aria-labelledby="add-ingredient-modal-title"
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<Box sx={{ width: "80vw", maxWidth: 400, bgcolor: "background.paper", p: 4, borderRadius: 2, boxShadow: 24 }}>
					<Typography id="add-ingredient-modal-title" variant="h6" gutterBottom>
						Add New Ingredient
					</Typography>
					<TextField
						fullWidth
						label="Enter Ingredient Name"
						variant="outlined"
						value={ingredientName}
						onChange={(e) => setIngredientName(e.target.value)}
						sx={{ marginBottom: 3 }}
					/>
					<Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
						Save
					</Button>
				</Box>
			</Modal>

			{/* Delete Confirmation Modal */}
			<Modal
				open={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				aria-labelledby="delete-confirmation-modal-title"
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<Box sx={{ width: "80vw", maxWidth: 400, bgcolor: "background.paper", p: 4, borderRadius: 2, boxShadow: 24 }}>
					<Typography id="delete-confirmation-modal-title" variant="h6" gutterBottom>
						Delete Ingredient
					</Typography>
					{meals.length > 0 ? (
						<>
							<Typography gutterBottom>{deleteModalMessage}</Typography>
							<Box component="ul" sx={{ mt: 2, pl: 3 }}>
								{meals.map((meal) => (
									<li key={meal.name}>
										<Typography variant="body2">{meal.name}</Typography>
									</li>
								))}
							</Box>
						</>
					) : (
						<Typography>{deleteModalMessage}</Typography>
					)}
					<Box mt={3} display="flex" justifyContent="space-between">
						<Button variant="outlined" onClick={() => setIsDeleteModalOpen(false)}>
							Cancel
						</Button>
						<Button variant="contained" color="error" onClick={confirmDeleteIngredient}>
							Delete
						</Button>
					</Box>
				</Box>
			</Modal>

			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
				<Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default IngredientsList;
