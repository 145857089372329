import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Snackbar, Alert } from "@mui/material";
import api from "../utils/api";

interface CsvData {
	mealName: string;
	orderQuantity: number;
}

interface IngredientQuantity {
	name: string;
	quantity: string; // Cooked weight as a string
	measurementUnit: string;
	multiplier: number;
	rawQuantity: string; // Computed raw weight as a string
}

interface ShoppingListProps {
	csvData: CsvData[];
}

const ShoppingList: React.FC<ShoppingListProps> = ({ csvData }) => {
	const [shoppingList, setShoppingList] = useState<IngredientQuantity[]>([]);
	const [missingMeals, setMissingMeals] = useState<string[]>([]);
	const [mealsWithoutIngredients, setMealsWithoutIngredients] = useState<string[]>([]);
	const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: "success" | "error" }>({
		open: false,
		message: "",
		severity: "success"
	});
	const [loading, setLoading] = useState<boolean>(true);
	const printRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const generateShoppingList = async () => {
			try {
				const ingredientQuantities: Record<string, { cookedQuantity: number; measurementUnit: string; multiplier: number }> = {};
				const missingMealsSet = new Set<string>();
				const mealsWithoutIngredientsSet = new Set<string>();

				// Fetch all meals first to get their IDs
				const mealsResponse = await api.get("/meals");
				const meals = mealsResponse.data;

				for (const mealData of csvData) {
					const { mealName, orderQuantity } = mealData;

					if (!mealName) {
						console.error("Meal name is missing from CSV data.");
						continue;
					}

					// Find the meal by name from the list of all meals
					const meal = meals.find((m: any) => m.name.toLowerCase() === mealName.toLowerCase().trim());

					if (!meal) {
						console.error(`No meal found for name: ${mealName}`);
						missingMealsSet.add(mealName);
						continue;
					}

					// Fetch ingredients for the meal using the meal ID
					try {
						const ingredientsResponse = await api.get(`/meals/${meal.Id}/ingredients`);
						const mealIngredients = ingredientsResponse.data;

						if (!mealIngredients.length) {
							console.error(`Meal ${mealName} exists but has no ingredients.`);
							mealsWithoutIngredientsSet.add(mealName);
							continue;
						}

						mealIngredients.forEach((ingredient: any) => {
							const cookedQuantity = parseFloat(ingredient.quantity) * orderQuantity;
							const multiplier = ingredient.multiplier || 1;

							if (ingredientQuantities[ingredient.ingredientName]) {
								ingredientQuantities[ingredient.ingredientName].cookedQuantity += cookedQuantity;
							} else {
								ingredientQuantities[ingredient.ingredientName] = {
									cookedQuantity: cookedQuantity,
									measurementUnit: ingredient.measurementUnit,
									multiplier: multiplier
								};
							}
						});
					} catch (apiError) {
						console.error(`Failed to fetch ingredients for meal: ${mealName}`, apiError);
					}
				}

				const formattedQuantities = Object.entries(ingredientQuantities).map(([name, { cookedQuantity, measurementUnit, multiplier }]) => {
					let rawQuantity = cookedQuantity / multiplier;
					let unit = measurementUnit;

					// Convert grams to kg or ml to liters if appropriate
					if (unit === "grams" && rawQuantity >= 1000) {
						rawQuantity /= 1000;
						cookedQuantity /= 1000;
						unit = "kg";
					} else if (unit === "ml" && rawQuantity >= 1000) {
						rawQuantity /= 1000;
						cookedQuantity /= 1000;
						unit = "liters";
					}

					// Format the quantities to remove unnecessary trailing zeros and convert to strings
					const formatNumber = (num: number) => num.toFixed(2).replace(/\.?0+$/, "");

					return {
						name,
						quantity: `${formatNumber(cookedQuantity)} ${unit}`, // Cooked weight
						rawQuantity: `${formatNumber(rawQuantity)} ${unit}`, // Raw weight
						measurementUnit: unit,
						multiplier
					};
				});

				// Sort by ingredient name in ascending order
				formattedQuantities.sort((a, b) => a.name.localeCompare(b.name));

				setShoppingList(formattedQuantities);
				setMissingMeals(Array.from(missingMealsSet));
				setMealsWithoutIngredients(Array.from(mealsWithoutIngredientsSet));
			} catch (error) {
				console.error("Error generating shopping list:", error);
				setSnackbar({ open: true, message: "Error generating shopping list", severity: "error" });
			} finally {
				setLoading(false);
			}
		};

		generateShoppingList();
	}, [csvData]);

	const handlePrint = () => {
		if (printRef.current) {
			const printContent = printRef.current.innerHTML;
			const printWindow = window.open("", "", "width=800,height=600");
			printWindow?.document.write(`
				<html>
					<head>
						<title>Print Prep List</title>
						<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap">
						<style>
							body {
								font-family: 'Roboto', sans-serif;
							}
							table {
								width: 100%;
								border-collapse: collapse;
							}
							th, td {
								border: 1px solid #ddd;
								padding: 8px;
								text-align: left;
							}
							th {
								background-color: #f2f2f2;
							}
							.logo {
								margin-bottom: 20px;
							}
						</style>
					</head>
					<body>
						<div class="logo">
							<img src="/LiveFit-Logo-Black_180x.avif" alt="LiveFit Logo" style="width: 150px;">
						</div>
						${printContent}
					</body>
				</html>
			`);
			printWindow?.document.close();
			printWindow?.print();
		}
	};

	const handleCreateMissingMeals = async () => {
		try {
			// Fetch existing meals to determine the highest ID
			const response = await api.get<any[]>("/meals");
			const existingMeals = response.data;

			// Find the highest existing ID and initialize a counter
			let highestId = existingMeals.length > 0 ? Math.max(...existingMeals.map((meal) => parseInt(meal.Id, 10))) : 0;

			// Create each missing meal with a unique, incremented ID
			for (const mealName of missingMeals) {
				highestId += 1; // Increment the ID
				const newMeal = { Id: highestId.toString(), name: mealName };

				await api.post("/meals", newMeal);
			}

			setMissingMeals([]); // Clear the missing meals after creation
			setSnackbar({ open: true, message: "Missing meals created successfully", severity: "success" });
		} catch (error) {
			console.error("Error creating missing meals:", error);
			setSnackbar({ open: true, message: "Failed to create missing meals", severity: "error" });
		}
	};

	const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

	return (
		<Box mt={4}>
			{loading ? (
				<Typography>Loading...</Typography>
			) : (
				<>
					{/* Display missing meals */}
					{missingMeals.length > 0 && (
						<Box mt={4}>
							<Typography variant="h6" color="error">
								The following meals from the order spreadsheet do not exist in the database:
							</Typography>
							<ul>
								{missingMeals.map((meal, index) => (
									<li key={index}>{meal}</li>
								))}
							</ul>
							<Button variant="contained" color="error" onClick={handleCreateMissingMeals} sx={{ mt: 2 }}>
								Create Missing Meals
							</Button>
						</Box>
					)}

					{/* Display meals without ingredients */}
					{mealsWithoutIngredients.length > 0 && (
						<Box mt={4}>
							<Typography variant="h6" color="warning">
								The following meals exist in the database but have no ingredients listed:
							</Typography>
							<ul>
								{mealsWithoutIngredients.map((meal, index) => (
									<li key={index}>{meal}</li>
								))}
							</ul>
						</Box>
					)}

					<Typography variant="h4" mt={5} mb={5} gutterBottom>
						Prep List
					</Typography>

					<Box ref={printRef}>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>
											<b>Ingredient</b>
										</TableCell>
										<TableCell>
											<b>Raw Weight / Quantity</b>
										</TableCell>
										<TableCell>
											<b>Cooked Weight / Quantity</b>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{shoppingList.map((ingredient) => (
										<TableRow key={ingredient.name}>
											<TableCell>{ingredient.name}</TableCell>
											<TableCell>{ingredient.rawQuantity}</TableCell>
											<TableCell>{ingredient.quantity}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						{/* Order Summary Addendum */}
						<Typography variant="h4" mt={15} mb={3}>
							Order Summary
						</Typography>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>
											<b>Meal Name</b>
										</TableCell>
										<TableCell>
											<b>Order Quantity</b>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{csvData
										.filter((order) => order.mealName && order.orderQuantity && !isNaN(order.orderQuantity)) // Filter out invalid entries
										.map((order, index) => (
											<TableRow key={index}>
												<TableCell>{order.mealName}</TableCell>
												<TableCell>{order.orderQuantity}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>

					<Box mt={3} textAlign="right">
						<Button variant="contained" color="primary" onClick={handlePrint}>
							Print
						</Button>
					</Box>
				</>
			)}

			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
				<Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default ShoppingList;
