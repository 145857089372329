import React, { useState } from "react";
import api from "../utils/api";

const AddMeal: React.FC = () => {
	const [name, setName] = useState("");
	const [ingredients, setIngredients] = useState<string[]>([]);
	const [newIngredient, setNewIngredient] = useState("");
	const [message, setMessage] = useState("");

	const handleAddIngredient = () => {
		if (newIngredient.trim() !== "") {
			setIngredients([...ingredients, newIngredient]);
			setNewIngredient("");
		}
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const meal = { name, ingredients };

		try {
			const response = await api.post("/meals", meal);
			setMessage(`Meal added successfully: ${response.data.message}`);
		} catch (error) {
			console.error("Error adding meal:", error);
			setMessage("Failed to add meal");
		}
	};

	return (
		<div>
			<h3>Add New Meal</h3>
			<form onSubmit={handleSubmit}>
				<div>
					<label>Meal Name:</label>
					<input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
				</div>
				<div>
					<label>Ingredients:</label>
					<div>
						{ingredients.map((ingredient, index) => (
							<span key={index} style={{ marginRight: "5px" }}>
								{ingredient}
							</span>
						))}
					</div>
					<input type="text" value={newIngredient} onChange={(e) => setNewIngredient(e.target.value)} />
					<button type="button" onClick={handleAddIngredient}>
						Add Ingredient
					</button>
				</div>
				<button type="submit">Add Meal</button>
			</form>
			{message && <p>{message}</p>}
		</div>
	);
};

export default AddMeal;
