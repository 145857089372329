import React, { useState } from "react";
import { Box, Typography, Container, Snackbar, Alert } from "@mui/material";
import { parse } from "papaparse";
import NavBar from "../components/NavBar";
import UploadCsv from "../components/UploadCsv";
import ShoppingList from "../components/ShoppingList";

interface CsvData {
	mealName: string;
	orderQuantity: number;
}

const HomePage: React.FC = () => {
	const [csvData, setCsvData] = useState<CsvData[] | null>(null);
	const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: "success" | "error" }>({
		open: false,
		message: "",
		severity: "success"
	});

	const handleCsvUpload = (file: File) => {
		// Use PapaParse to parse the CSV file
		parse(file, {
			header: true,
			skipEmptyLines: true,
			complete: (result) => {
				if (result.errors.length > 0) {
					setSnackbar({ open: true, message: "Please select a file to upload", severity: "error" });
					console.error(result.errors);
					return;
				}

				const parsedData: CsvData[] = result.data.map((row: any) => ({
					mealName: row["Products / Name"]?.trim() || "", // Handle extra spaces or null values
					orderQuantity: parseInt(row["Order Items / Net Quantity"], 10)
				}));

				// Sort the parsed data by meal name (ascending order)
				parsedData.sort((a, b) => a.mealName.localeCompare(b.mealName));

				setCsvData(parsedData);
				setSnackbar({ open: true, message: "CSV file uploaded successfully", severity: "success" });
			}
		});
	};

	const handleCloseSnackbar = () => {
		setSnackbar({ ...snackbar, open: false });
	};

	return (
		<>
			<NavBar />
			<Container maxWidth="md">
				<Box mt={8} textAlign="center">
					<Typography variant="h3" gutterBottom>
						Meal Planner
					</Typography>
					<Typography variant="subtitle1" color="textSecondary">
						Upload your order csv to generate a prep list
					</Typography>
				</Box>

				<UploadCsv onUpload={handleCsvUpload} />

				{csvData && <ShoppingList csvData={csvData} />}

				<Snackbar
					open={snackbar.open}
					autoHideDuration={6000}
					onClose={handleCloseSnackbar}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
					<Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
						{snackbar.message}
					</Alert>
				</Snackbar>
			</Container>
		</>
	);
};

export default HomePage;
