import { forwardRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Toolbar, Box, Button, IconButton, Drawer, List, ListItem, ListItemText, ListItemButton, Typography, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useAuth } from "react-oidc-context";

const NavBar = forwardRef<HTMLDivElement, {}>((props, ref) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const auth = useAuth();

	const toggleDrawer = (open: boolean) => () => {
		setDrawerOpen(open);
	};

	// Handler to initiate logout
	const handleLogout = async () => {
		try {
			await auth.signoutRedirect({ post_logout_redirect_uri: process.env.REACT_APP_LOGOUT_REDIRECT_URI || "" });
		} catch (error) {
			console.error("Error during logout:", error);
		}
	};

	return (
		<AppBar
			position="sticky"
			color="inherit" // Use "inherit" to customize the color and keep it consistent
			elevation={1} // Add slight elevation if needed
			sx={{
				backgroundColor: "#ffffff", // White background for the navbar
				zIndex: (theme) => theme.zIndex.drawer + 1 // Make sure the navbar is above other elements
			}}
			ref={ref} // Pass the ref here
		>
			<Toolbar>
				{/* Logo */}
				<Box component={RouterLink} to="/" sx={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
					<img src="/LiveFit-Logo-Black_180x.avif" alt="LiveFit Logo" height="40" />
				</Box>
				<Box flexGrow={1} />
				{/* Menu Button for Mobile */}
				<Box sx={{ display: { xs: "block", md: "none" } }}>
					<IconButton edge="start" aria-label="menu" onClick={toggleDrawer(true)}>
						<MenuIcon />
					</IconButton>
				</Box>
				{/* Buttons for Desktop */}
				<Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
					<Button color="inherit" component={RouterLink} to="/meals">
						Meals
					</Button>
					<Button color="inherit" component={RouterLink} to="/ingredients/">
						Ingredients
					</Button>
					<Button
						color="inherit"
						onClick={handleLogout}
						startIcon={<ExitToAppIcon />}
						sx={{ marginLeft: 2 }} // Add some spacing to the left
					>
						Logout
					</Button>
				</Box>
				{/* Drawer for Mobile Menu */}
				<Drawer
					anchor="right"
					open={drawerOpen}
					onClose={toggleDrawer(false)}
					sx={{
						"& .MuiDrawer-paper": {
							width: 280,
							padding: 2,
							zIndex: (theme) => theme.zIndex.appBar + 1,
							display: "flex", // Use flexbox to control the layout
							flexDirection: "column", // Stack elements vertically
							justifyContent: "flex-start", // Align items at the top
							paddingTop: "64px" // Add extra padding to move the buttons down
						}
					}}>
					<List>
						<ListItem disablePadding>
							<ListItemButton component={RouterLink} to="/meals" onClick={toggleDrawer(false)}>
								<RestaurantIcon sx={{ marginRight: 1 }} />
								<ListItemText primary={<Typography variant="h6">Meals</Typography>} />
							</ListItemButton>
						</ListItem>
						<Divider />
						<ListItem disablePadding>
							<ListItemButton component={RouterLink} to="/ingredients/" onClick={toggleDrawer(false)}>
								<AddCircleIcon sx={{ marginRight: 1 }} />
								<ListItemText primary={<Typography variant="h6">Ingredients</Typography>} />
							</ListItemButton>
						</ListItem>
						<Divider />
						<ListItem disablePadding>
							<ListItemButton
								onClick={() => {
									toggleDrawer(false)();
									handleLogout();
								}}>
								<ExitToAppIcon sx={{ marginRight: 1 }} />
								<ListItemText primary={<Typography variant="h6">Logout</Typography>} />
							</ListItemButton>
						</ListItem>
					</List>
				</Drawer>
			</Toolbar>
		</AppBar>
	);
});

export default NavBar;
