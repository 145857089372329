import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { CircularProgress, Box, Typography } from "@mui/material";

interface PrivateRouteProps {
	children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
	const auth = useAuth();
	const { isAuthenticated, isLoading, signinRedirect } = auth;

	useEffect(() => {
		// Ensure all required environment variables are set
		const redirectUri = process.env.REACT_APP_AUTH_REDIRECT_URI;
		const authority = process.env.REACT_APP_AUTHORITY;
		const clientId = process.env.REACT_APP_CLIENT_ID;

		if (!redirectUri || !authority || !clientId) {
			console.error("Missing environment variables for Cognito authentication.");
			return;
		}

		// Check if the user is logging out
		const isLogout = new URLSearchParams(window.location.search).get("logout") === "true";
		// Don't require sign in for local development but force it in production
		if (process.env.NODE_ENV !== "development" && !isAuthenticated && !isLoading && !isLogout) {
			signinRedirect().catch((error) => {
				console.error("Error during sign-in redirect:", error);
			});
		}
	}, [isAuthenticated, isLoading, signinRedirect]);

	// Handle loading state with a nicer UI
	if (isLoading) {
		return (
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
				<CircularProgress />
				<Typography variant="h6" sx={{ mt: 2 }}>
					Loading...
				</Typography>
			</Box>
		);
	}

	// Render children if authenticated
	return isAuthenticated || process.env.NODE_ENV === "development" ? children : null;
};

export default PrivateRoute;
